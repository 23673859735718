import React, {Component, Suspense} from 'react'
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom'
import './scss/style.scss'
import {SETVAL} from "./etc/DefaultConfig";
import {NavermapsProvider} from "react-naver-maps";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Join = React.lazy(() => import('./views/pages/join/Join'))
const ID = React.lazy(() => import('./views/pages/idcheck/IDCheck'))
const PW = React.lazy(() => import('./views/pages/pwcheck/PWCheck'))
const JoinTos = React.lazy(() => import('./views/pages/tos/JoinTos'))
const Tos1 = React.lazy(() => import('./views/pages/tos/Tos1'))
const Tos2 = React.lazy(() => import('./views/pages/tos/Tos2'))

class App extends Component {
    render() {
        return (
            <NavermapsProvider
                ncpClientId={'9y3qcrnnqm'}>
                <HashRouter>
                    <Suspense fallback={loading}>
                        <Routes>
                            <Route exact path="/join" name="join Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <Join/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>
                            <Route exact path="/joinTos" name="join Tos Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <JoinTos/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>
                            <Route exact path="/tos1" name="join Tos Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <Tos1/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>
                            <Route exact path="/tos2" name="join Tos Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <Tos2/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>
                            <Route exact path="/id" name="idCheck Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <ID/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>
                            <Route exact path="/pw" name="pwCheck Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <PW/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>
                            <Route exact path="/login" name="Login Page"
                                   element={
                                       !localStorage.getItem(SETVAL.token) ? (
                                           <Login/>
                                       ) : (
                                           <Navigate to="/order"/>
                                       )
                                   }/>

                            <Route path="*" name="Home"
                                   element={
                                       localStorage.getItem(SETVAL.token) ? (
                                           <DefaultLayout/>
                                       ) : (
                                           <Navigate to="/login"/>
                                       )
                                   }/>
                        </Routes>
                    </Suspense>
                </HashRouter>
            </NavermapsProvider>
        )
    }
}

export default App
